$(document).ready(function() {
  $(function() {
    $(".referral-code-block").on("click", function() {
      const copyTextarea = $("#copy-container");
      copyTextarea.select();
      document.execCommand("copy");
      copyTextarea.blur();
    });

    $('[data-toggle="tooltip"]').tooltip({
      animated: "fade",
      placement: "bottom",
      trigger: "click"
    });

    var urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('code')){
      $('#copy-container').val(urlParams.get('code'));
    }
  });
});
